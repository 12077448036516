import React, { useEffect, useState } from 'react';
import { fetchCourses } from '../services/CourseService';

const Categories: React.FC = () => {
  const [courses, setCourses] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getCourses = async () => {
      try {
        const data = await fetchCourses();
        setCourses(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch courses', error);
        setLoading(false);
      }
    };

    getCourses();
  }, []);

  const categories = Array.from(new Set(courses.map(course => course.category)));

  const filteredCourses = selectedCategory
    ? courses.filter(course => course.category === selectedCategory)
    : courses;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto py-16 px-6">
      <div className="flex flex-wrap justify-center gap-4 mb-8 mt-10">
        {categories.map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`inline-block bg-blue-100 text-gray-800 font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-200 transition ${
              selectedCategory === category ? 'bg-blue-300' : ''
            }`}
            style={{backgroundColor:"#050ADF", color:"white"}}
          >
            {category}
          </button>
        ))}
        {selectedCategory && (
          <button
            onClick={() => setSelectedCategory(null)}
            className="inline-block bg-red-100 text-red-800 font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-red-200 transition"
          >
            Show All
          </button>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredCourses.map((course) => (
          <div
            key={course.id}
            className="bg-white rounded-lg shadow-lg mx-2 cursor-pointer transform transition-transform 
              duration-500 ease-in-out hover:-translate-y-3 hover:scale-110 hover:border-orange-400 relative max-w-xs overflow-hidden"
            onClick={() => window.open(course.link, '_blank')}
          >
            <img
              src={course.image?.url || "https://via.placeholder.com/150"}
              alt={course.title}
              className="w-full h-40 object-cover mb-4 rounded"
            />
            <h4 className="text-xl font-semibold text-left text-gray-800 mb-2 p-2">
              {course.title}
            </h4>
            <div className="flex items-center justify-between text-gray-600 mb-2 p-2">
              <div className="flex items-center">
                <img
                  src="https://img.icons8.com/ios-filled/24/050ADF/user-group-man-man.png"
                  alt="Users"
                  className="mr-2"
                />
                <span>{course.enrolledCount || "N/A"} users</span>
              </div>
              <div className="flex items-center">
                <img
                  src="https://img.icons8.com/ios-filled/24/050ADF/time.png"
                  alt="Duration"
                  className="mr-2"
                />
                <span>{course.duration || "N/A"} min</span>
              </div>
            </div>
            <hr className="border-t border-blue-400 mb-2" />
            <p className="text-gray-600 mb-4 text-left pl-2">
              {course.description || "No description available."}
            </p>
            <div className="flex justify-between items-center p-2">
              <span
                className="pl-2 w-14 font-semibold text-white rounded-full"
                style={{ backgroundColor: '#050ADF' }}
              >
                ${course.price || "Free"}
              </span>
              <button
                onClick={() => window.open(course.link, '_blank')}
                className="border-2 border-blue-400 rounded-full w-14 font-semibold"
                style={{ color: '#050ADF', borderColor: "#050ADF" }}
              >
                Enroll
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
