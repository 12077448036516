import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import sideface from '../assets/craousel/language_schools_virtual-classroom_header.jpg'

const TestimonialCarousel: React.FC = () => {
  return (
    <section className=" py-16 px-4"
    style={{backgroundColor:"#F7F9FA"}}>
      <div className="container mx-auto flex flex-col lg:flex-row items-center">
        {/* Swiper Carousel for Testimonial */}
        {/* <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            navigation
            loop
            modules={[Navigation]}
          >
            <SwiperSlide>
              <div className="bg-white p-8 rounded-lg shadow-lg relative">
                <div className="flex flex-col items-center mb-4">
                  <img
                    src="https://media.licdn.com/dms/image/v2/D4E03AQH5aBUJYW48BA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698145719037?e=2147483647&v=beta&t=KUc30tH_TvBfExWvudhA7IivMxDocZaBS6aXNd5cQj8"
                    alt="Amir Khan"
                    className="w-24 h-24 rounded-full mb-4"
                  />
                  <p className="text-gray-600 text-center mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.
                  </p>
                  <h4 className="text-lg font-semibold text-center">Amir Khan</h4>
                </div>
                <div className="absolute top-4 right-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <circle cx="12" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                    <circle cx="16" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                    <circle cx="8" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                    <circle cx="4" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                    <circle cx="20" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                  </svg>
                </div>
                <div className="absolute bottom-4 left-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 12H5m7-7l-7 7 7 7" />
                  </svg>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-white p-8 rounded-lg shadow-lg relative">
                <div className="flex flex-col items-center mb-4">
                  <img
                    src="https://media.licdn.com/dms/image/v2/D4E03AQH5aBUJYW48BA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698145719037?e=2147483647&v=beta&t=KUc30tH_TvBfExWvudhA7IivMxDocZaBS6aXNd5cQj8"
                    alt="Amir Khan"
                    className="w-24 h-24 rounded-full mb-4"
                  />
                  <p className="text-gray-600 text-center mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.
                  </p>
                  <h4 className="text-lg font-semibold text-center">Amir Khan</h4>
                </div>
                <div className="absolute top-4 right-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <circle cx="12" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                    <circle cx="16" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                    <circle cx="8" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                    <circle cx="4" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                    <circle cx="20" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
                  </svg>
                </div>
                <div className="absolute bottom-4 left-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 12H5m7-7l-7 7 7 7" />
                  </svg>
                </div>
                <div className="absolute bottom-4 right-4 flex space-x-2">
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div> */}

        {/* Static Content on the Right */}
        {/* <div className="lg:w-1/2 w-full lg:pl-12">
          <h3 className="text-blue-500 font-semibold uppercase text-sm mb-2">Testimonials</h3>
          <h2 className="text-4xl font-bold text-gray-800 mb-4">Our Students Say About Us</h2>
          <p className="text-gray-600 text-lg mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.
          </p>
          <p className="text-gray-600 text-lg">
            Laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim.
          </p>
        </div> */}
              {/* Text Section */}
                    {/* Image Section */}
      <div className="relative w-full lg:w-1/2 max-w-sm mx-auto">
        <div className="absolute top-0 right-0 w-full h-full rounded-lg" style={{ transform: 'translate(10px, -10px)', backgroundColor:"#050ADF" }}></div>
        <img
          src={sideface}
          alt="Virtual Classroom"
          className="relative z-10 w-full h-auto rounded-lg"
        />
      </div>
      <div className="w-full lg:w-1/2 mt-8 lg:mt-0 lg:ml-12">
        <h2 className="text-3xl lg:text-4xl font-bold mb-4 text-gray-800"
            style={{color:'#050ADF',}}>About Us</h2>
        <p className="text-gray-700 text-lg leading-relaxed mb-4">
        At SparkMinds, we believe in shaping the future through innovative and accessible
education. Our platform connects learners worldwide with industry-leading
professionals, offering expertly designed courses in technology, business, and beyond.
Whether you're entering the workforce or transitioning careers, we provide the
knowledge and skills you need to succeed in an ever-evolving world. With a focus on
practical learning and professional growth, SparkMinds is your partner in unlocking new
opportunities and achieving your goals.
        </p>
        {/* <p className="text-gray-900 font-semibold mb-6">
          Choose us for quality, convenience, and success.
        </p> */}
        {/* <button className="px-6 py-3 bg-blue-600 text-white rounded-full text-lg font-medium">
          Learn More
        </button> */}
      </div>
      </div>
    </section>
  );
};

export default TestimonialCarousel;
