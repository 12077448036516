import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import SaleBanner from './components/SaleBanner'; // Import the SaleBanner
import Header from './components/Header'; // Import the Header
import Home from './pages/Home';
import Categories from './pages/Categories';
import Teacher from './pages/Teacher';
import './App.css';


const App: React.FC = () => {
  return (
    <Router>
      {/* <SaleBanner /> Render SaleBanner first */}
      {/* <Header />Render Header next */}
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/become-teacher" element={<Teacher />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
