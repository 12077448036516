// src/services/CourseService.ts
import axios from 'axios';

export interface Job {
  _id?: string;
  title: string;
  description: string;
  qualification: string;
  active: boolean;
  createdDate?: string;
}

// Add your fetch, add, and update job functions here


const API_BASE_URL = 'https://spark-mind.vercel.app/api/courses';

export const fetchCourses = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

// const API_URL = 'https://spark-mind.vercel.app/api/job/';

// export const fetchJobs = async () => {
//   try {
//     const response = await axios.get(API_URL, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching jobs:', error);
//     throw error; // To catch it in the component and display
//   }
// };