import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to get the current path
import Header from './Header';
import Footer from './Footer';
import SaleBanner from './SaleBanner';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const location = useLocation(); // Get the current location

  const handleCloseBanner = () => {
    setIsBannerVisible(false); // Hide the banner when closed
  };

  // Render SaleBanner only on the homepage ("/")
  const isHomePage = location.pathname === '/';

  return (
    <div className="flex flex-col min-h-screen">
      {/* Conditionally render the SaleBanner only on the homepage and only if it's visible */}
      {isHomePage && isBannerVisible && <SaleBanner onClose={handleCloseBanner} />}
      
      {/* Pass the banner visibility state to the Header to adjust its position */}
      <Header bannerVisible={isHomePage && isBannerVisible} />

      <main className="flex-grow">{children}</main>
      
      <Footer />
    </div>
  );
};

export default Layout;
