import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import img1 from '../assets/craousel/DeWatermark.ai_1725612061896.png';
import img2 from '../assets/craousel/DeWatermark.ai_1725613944374.png';
import { Pagination, Navigation } from 'swiper/modules';

const Carousel: React.FC = () => {
  return (
    <div className="relative w-full mt-[5.3rem] pb-4">
      <Swiper
        pagination={{
          type: 'bullets',
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="w-full"
      >
        {/* Slide 1 */}
        <SwiperSlide>
          <div className="relative w-full">
            <img src={img1} alt="Slide 1" className="w-full h-auto object-cover" />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-blue-800 bg-opacity-50 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 rounded-lg text-center max-w-[90%] md:max-w-[80%]">
                <h2 className="text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-2 text-white">START LEARNING FROM HOME</h2>
                <h1 className="text-xl sm:text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-white">Connect With Our Expert and Start Learning Today</h1>
                <button className="mt-4 px-4 sm:px-6 py-2  text-white rounded-full text-sm sm:text-md md:text-lg"
                style={{backgroundColor:"#050ADF"}}
                >Enroll Now</button>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* Slide 2 */}
        <SwiperSlide>
          <div className="relative w-full">
            <img src={img2} alt="Slide 2" className="w-full h-auto object-cover" />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-blue-800 bg-opacity-50 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 rounded-lg text-center max-w-[90%] md:max-w-[80%]">
                <h2 className="text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-2 text-white">LEARN AT YOUR OWN PACE</h2>
                <h1 className="text-xl sm:text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-white">Join Our Courses and Enhance Your Skills</h1>
                <button className="mt-4 px-4 sm:px-6 py-2  text-white rounded-full text-sm sm:text-md md:text-lg"
                                style={{backgroundColor:"#050ADF"}}>Join Now</button>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* Add more slides as needed */}
      </Swiper>
    </div>
  );
};

export default Carousel;
