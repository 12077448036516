import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { fetchCourses } from '../services/CourseService';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const TopCategories: React.FC = () => {
  const [activeCard, setActiveCard] = useState<string | null>(null);
  const [courses, setCourses] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const getCourses = async () => {
      if (activeCard) {
        try {
          setLoading(true);
          const data = await fetchCourses();
          setCourses(data);
          setLoading(false);
        } catch (error) {
          console.error('Failed to fetch courses', error);
          setLoading(false);
        }
      }
    };

    getCourses();
  }, [activeCard]);

  const toggleCard = (cardName: string) => {
    setActiveCard(activeCard === cardName ? null : cardName);
  };

  const getArrowIcon = (cardName: string) => {
    return activeCard === cardName
      ? 'https://img.icons8.com/ios-filled/24/ffffff/up.png'
      : 'https://img.icons8.com/ios-filled/24/ffffff/down.png';
  };

  const getCardBackground = (cardName: string) => {
    return activeCard === cardName ? 'bg-gradient-to-r from-blue-300 to-blue-100' : '#F7F9FA';
  };

  const sliderSettings = {
  //   .slick-track {
  //     /* background-color: white; */
  //     padding: 2%;
  //     /* padding-right: 3%; */
  //     display: flex;
  //     align-items: center;
  // }
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    spaceBetween: 1, 
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500, // Set autoplay speed (in milliseconds)
    pauseOnHover: true, // Pause on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="text-center">
      <div className="mb-6">
        <h3 className=" font-semibold uppercase text-sm"
                        style={{color:"#050ADF"}}>Our Big Library</h3>
        <h2 className="text-4xl font-bold text-gray-800 mb-4">Top Categories</h2>
        {/* <p className="text-gray-600 text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed diam nonummy
        </p> */}
      </div>

      <div className=" p-6 flex flex-col lg:flex-row justify-center items-start space-y-8 lg:space-y-0 lg:space-x-8 ">
        
      <div
          className={`p-8 rounded-lg shadow-2xl border border-gray-300 w-full lg:w-1/3 
            relative max-w-md overflow-hidden cursor-pointer transform transition-transform 
            duration-500 ease-in-out hover:-translate-y-3 hover:scale-110 hover:border-orange-400 ${
            activeCard === 'development' ? '-translate-y-2' : ''
          } `}
          onClick={() => toggleCard('development')}
          style={{backgroundColor:'#F7F9FA'}}
        >
          <div className="flex justify-center mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="text-blue-500 cursor-pointer"
            >
              <path style={{color:"#050ADF"}} d="M5.854 4.146a.5.5 0 0 0-.708 0L2.146 7.146a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L3.207 7.5l2.647-2.646a.5.5 0 0 0 0-.708zM10.146 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 1 1-.708-.708L12.793 7.5 10.146 4.854a.5.5 0 0 1 0-.708z"/>
              {/* <path style={{color:"#050ADF"}} d="M7.5 3.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1H8v7h1.5a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5v-8z"/> */}
            </svg>
          </div>

          <h3 className="text-2xl font-semibold text-gray-800 text-left mb-4">Development</h3>
          <p className="text-black mb-6 text-left">
          Unlock your potential with courses on coding, app development, and software
          engineering. Build the tools of tomorrow, today.
          </p>
          <div className="flex justify-center">
            <button className=" text-white rounded-full p-3"
                            style={{backgroundColor:"#050ADF"}}>
              <img src={getArrowIcon('development')} alt="Toggle" />
            </button>
          </div>
        </div>

        
        <div
          className={`p-8 rounded-lg shadow-2xl border border-gray-300  w-full lg:w-1/3 
            relative max-w-md overflow-hidden cursor-pointer transform transition-transform 
            duration-500 ease-in-out hover:-translate-y-3 hover:scale-110 hover:border-orange-400
            ${
            activeCard === 'IT and Software' ? '-translate-y-2' : ''
          } `}
          onClick={() => toggleCard('IT and Software')}
          style={{backgroundColor:'#F7F9FA',}}
        >
<div className="flex justify-center mb-6">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    fill="currentColor"
    viewBox="0 0 16 16"
    className="text-blue-500 cursor-pointer"
  >
    <path style={{color:"#050ADF"}} d="M3 3a2 2 0 0 0-2 2v7h16V5a2 2 0 0 0-2-2H3zm0 1h10a1 1 0 0 1 1 1v6H2V5a1 1 0 0 1 1-1zm0 10v-1h10v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
    <path style={{color:"#050ADF"}} d="M9.854 6.146a.5.5 0 0 0-.708 0l-1.5 1.5a.5.5 0 0 0 0 .708l1.5 1.5a.5.5 0 0 0 .708-.708L8.707 8l1.147-1.146a.5.5 0 0 0 0-.708zm-3 0a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 1 1-.708-.708L7.293 8l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
  </svg>
</div>

          <h3 className="text-2xl font-semibold text-gray-800 mb-4">IT and Software</h3>
          <p className="text-black mb-6">
          Master the world of technology with in-depth training on system administration,
          cybersecurity, and IT infrastructure.
          </p>
          <div className="flex justify-center">
            <button className=" text-white rounded-full p-3"
                            style={{backgroundColor:"#050ADF"}}>
              <img src={getArrowIcon('IT and Software')} alt="Toggle" />
            </button>
          </div>
        </div>


        <div
          className={`p-8 rounded-lg shadow-2xl border border-gray-300 w-full lg:w-1/3 
            relative max-w-md overflow-hidden cursor-pointer transform transition-transform 
            duration-500 ease-in-out hover:-translate-y-3 hover:scale-110 hover:border-orange-400 ${
            activeCard === 'marketing' ? '-translate-y-2' : ''
          } `}
          onClick={() => toggleCard('marketing')}
          style={{backgroundColor:'#F7F9FA'}}
        >
<div className="flex justify-center mb-6">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    fill="currentColor"
    viewBox="0 0 16 16"
    className="text-blue-500 cursor-pointer"
  >
    <path style={{color:"#050ADF"}} d="M13 3v10h-1V3h1zM1 5.5a1.5 1.5 0 0 1 3 0v5a1.5 1.5 0 0 1-3 0v-5zm10.5-1.855a1.5 1.5 0 0 0-2.037-.924l-6.406 3a.5.5 0 0 0 0 .916l6.406 3a1.5 1.5 0 0 0 2.037-.924V3.645z"/>
  </svg>
</div>

          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Marketing</h3>
          <p className="text-black mb-6">
          Gain the skills to lead, strategize, and grow in today’s fast-paced business
          environment. Learn entrepreneurship, management, and more.
          </p>
          <div className="flex justify-center">
            <button className=" text-white rounded-full p-3"
                            style={{backgroundColor:"#050ADF"}}>
              <img src={getArrowIcon('marketing')} alt="Toggle" />
            </button>
          </div>
        </div>
      </div>
      {activeCard && (
  <div className=" p-8 rounded-lg shadow-2xl border border-gray-300 transition-all mx-4 my-4 bg-blue-50"
        style={{backgroundColor:"#F7F9FA"}}
  >
    <h3 className="text-3xl font-bold text-left text-black mb-6">
      {activeCard.charAt(0).toUpperCase() + activeCard.slice(1)}
    </h3>
    {loading ? (
      <div>Loading...</div>
    ) : (
      <>
   <Slider {...sliderSettings} className=" ">
                {courses.map((course) => (
                  <div
                    key={course.id}
                    className="bg-white rounded-lg shadow-lg mx-4 my-2 flex items-center
                    relative max-w-xs overflow-hidden cursor-pointer transform transition-transform 
                    duration-500 ease-in-out hover:-translate-y-3 hover:scale-110"
                    onClick={() => window.open(course.link, '_blank')} // Open enroll link when card is clicked
                  >
                    <img
                      src={course.image.url || "https://via.placeholder.com/150"}
                      alt={course.title}
                      className="w-full h-40 object-cover mb-4 rounded"
                    />
                    <h4 className="text-xl font-semibold text-left text-gray-800 mb-2 p-2">
                      {course.title}
                    </h4>
                    <div className="flex items-center justify-between text-gray-600 mb-2 p-2">
                      <div className="flex items-center">
                        <img
                          src="https://img.icons8.com/ios-filled/24/050ADF/user-group-man-man.png"
                          alt="Users"
                          className="mr-2"
                        />
                        <span>{course.enrolledCount || "N/A"} users</span>
                      </div>
                      <div className="flex items-center">
                        <img
                          src="https://img.icons8.com/ios-filled/24/050ADF/time.png"
                          alt="Duration"
                          className="mr-2"
                        />
                        <span>{course.duration || "N/A"} min</span>
                      </div>
                    </div>
                    <hr className="border-t border-blue-400 mb-2" />
                    <p className="text-gray-600 mb-4 text-left p-2">
                      {course.description || "No description available."}
                    </p>
                    <div className="flex justify-between items-center p-2">
                      <span className="w-14 font-semibold text-white rounded-full" style={{ backgroundColor: '#050ADF' }}>
                        ${course.price || "Free"}
                      </span>
                      <button
                        onClick={() => window.open(course.link, '_blank')}
                        className="border-2 rounded-full w-14 font-semibold"
                        style={{ color: '#050ADF', borderColor: '#050ADF' }}
                      >
                        Enroll
                      </button>
                    </div>
                  </div>
                ))}
              </Slider>
        <div className="mt-4 text-right">
          <button
            onClick={() => navigate('/categories')}
            className=" underline font-semibold "
            style={{color:'#050ADF',}}
          >
            View All Courses
          </button>
        </div>
      </>
    )}
  </div>
)}


    </section>
  );
};

export default TopCategories;
