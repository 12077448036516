import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/cut.png";

interface HeaderProps {
  bannerVisible: boolean;
}

const Header: React.FC<HeaderProps> = ({ bannerVisible }) => {
  useEffect(() => {
    // Code for Google Translate (if needed)
  }, []);

  return (
    <header
      className={`fixed left-0 w-full flex flex-col md:flex-row justify-between items-center p-3 md:p-5 bg-white shadow-md z-40`}
      style={{ top: bannerVisible ? '40px' : '0px', paddingTop: bannerVisible ? '60px' : '' }}
    >
      <div className="flex items-center w-full md:w-auto justify-between md:justify-start">
        {/* Logo Section */}
        <Link to="/" className="font-bold text-xl text-blue-700 flex items-center">
          <img
            src={logo}
            alt="Spark Minds"
            className="h-14 md:h-14 ml-12 "
          />
        </Link>
        {/* Mobile Menu Toggle (if necessary) */}
        {/* <Link to="/categories" className="ml-6 hidden md:inline text-blue-600 text-lg">Categories</Link> */}
      </div>

      <div className="flex-grow mx-2 md:mx-4 max-w-xs sm:max-w-sm md:max-w-md w-full md:w-auto">
        {/* Search Bar */}
        <div className="relative">
          <input
            type="text"
            placeholder="Search Courses"
            className="w-full p-2 pl-10 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-300"
          />
          <span className="absolute left-3 top-2 text-gray-400">
            <svg className="h-5 w-5" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-4.35-4.35M17 10.5a6.5 6.5 0 10-13 0 6.5 6.5 0 0013 0z" />
            </svg>
          </span>
        </div>
      </div>

      <div className="flex items-center space-x-2 md:space-x-4 mt-4 md:mt-0">
        <Link to="/categories" className="md:hidden text-blue-900 text-lg">Categories</Link>
        {/* Login & Signup Buttons */}
        <a
          href="https://learn.sparkminds.live/learn/account/signin"
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 border  rounded-full flex items-center"
          style={{color:'#050ADF', borderColor:'#050ADF'}}
        >
          Login
        </a>
        <a
          href="https://learn.sparkminds.live/learn/account/signup"
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 border border-blue-600 text-blue-900 rounded-full flex items-center"
          style={{color:'#050ADF', borderColor:'#050ADF'}}
        >
          Sign Up
        </a>
      </div>
    </header>
  );
};

export default Header;
