import React, { useEffect, useState } from 'react';

interface SaleBannerProps {
  onClose: () => void; // Define onClose prop
}

const SaleBanner: React.FC<SaleBannerProps> = ({ onClose }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const endTime = new Date();
    endTime.setHours(23, 59, 59); // Set to end of the current day

    const updateCountdown = () => {
      const now = new Date();
      const difference = endTime.getTime() - now.getTime();

      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
    };

    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-orange-100 text-center py-2 px-4 text-black fixed top-0 left-0 w-full z-50 shadow-md">
      <div>
        <strong>“Welcome to SparkMinds</strong> We are a global community of educators committed to 
        empowering the next generation of innovators. Our mission is to deliver industry-relevant learning 
        experiences that prepare you for the future. Together, let's ignite your potential and shape your success.
      </div>
      {/* <div>
        <strong>Ends in {timeLeft}.</strong>
      </div> */}
      <button onClick={onClose} className="absolute border border-red-500 rounded-full w-6 right-1 top-1 text-red-500  font-semibold">
        X
      </button>
    </div>
  );
};

export default SaleBanner;
