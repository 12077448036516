import React, { useEffect, useState } from 'react';

interface Job {
  _id: string;
  title: string;
  description: string;
  qualification: string;
}

interface FormData {
  name: string;
  about: string;
  youtubeLink: string;
  resume: File | null;
}

const Teacher: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null); // To store the selected job for the form
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false); // For showing the popup
  const [showApplyButton, setShowApplyButton] = useState<boolean>(false); // Show apply button after selecting job
  const [showForm, setShowForm] = useState(false); // Control whether the form is shown

  const [formData, setFormData] = useState<FormData>({
    name: '',
    about: '',
    youtubeLink: '',
    resume: null,
  });

  // Fetch jobs from the API
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch('https://spark-mind.vercel.app/api/job/');
        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleJobClick = (job: Job) => {
    setSelectedJob(job); // Set the selected job
    setShowApplyButton(true); // Show the apply button after selecting a job
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setFormData({
      ...formData,
      resume: file,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.about);
    formDataToSend.append('youtubeLink', formData.youtubeLink);
    formDataToSend.append('status', 'Pending'); // Add default status as "Pending"

    if (formData.resume) {
      formDataToSend.append('resume', formData.resume); // Append the file to the form-data
    }

    try {
      const response = await fetch('https://spark-mind.vercel.app/api/request/', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        // Handle success response
        setShowSuccessPopup(true); // Show the success popup
        setTimeout(() => {
          setShowSuccessPopup(false); // Hide popup after 3 seconds
          setShowForm(false); // Close the form after success
        }, 3000);

        // Reset form after submission
        setFormData({
          name: '',
          about: '',
          youtubeLink: '',
          resume: null,
        });
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('An error occurred during form submission:', error);
    }
  };

  // Function to manually close the form
  const handleCloseForm = () => {
    setShowForm(false);
  };

  // Function to manually close the selected job modal
  const handleCloseSelectedJob = () => {
    setSelectedJob(null);
  };

  return (
    <div className="container mt-5 mx-auto py-24 px-4 md:px-12">
      {/* <h1 className="text-4xl font-extrabold text-center text-blue-600 mb-8">
        Become a Teacher
      </h1>
      <p className="text-lg text-center text-gray-600 mb-12 max-w-2xl mx-auto">
        Select a job and fill out the form to apply.
      </p> */}

      {/* Display Jobs */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Available Jobs</h2>
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {jobs.map((job) => (
            <li 
              key={job._id}
              className="p-4 bg-white rounded-lg shadow-lg hover:bg-blue-100 cursor-pointer border border-black transition-transform transform hover:scale-105"
              onClick={() => handleJobClick(job)}
            >
              <h3 className="text-xl font-semibold text-blue-600">{job.title}</h3>
              <p className="text-black"><b>description: </b>{job.description}</p>
              <p className="text-black "><b>Qualification:</b> {job.qualification}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Show Selected Job in Middle of Screen */}
      {selectedJob && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
          <div className="relative bg-white p-8 rounded-lg shadow-lg w-full md:w-1/2 max-h-[80vh] overflow-y-auto">
            {/* Close Button for Selected Job */}
            <button
              className="absolute top-3 right-4 text-red-500 hover:text-gray-900 text-2xl font-bold"
              onClick={handleCloseSelectedJob}
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold text-blue-600 mb-4">{selectedJob.title}</h2>
            <p className="text-lg text-black mb-4"><b>description: </b>{selectedJob.description}</p>
            <p className="text-lg text-black mb-6"><b>Qualification: </b> {selectedJob.qualification}</p>

            {showApplyButton && (
              <button
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none"
                onClick={() => setShowForm(true)}
              >
                Apply for this Job
              </button>
            )}
          </div>
        </div>
      )}

      {/* Show Form when Apply Button is clicked */}
      {showForm && selectedJob && (
        <div className="fixed inset-0 flex items-center justify-center pt-14 bg-black bg-opacity-50 z-20 overflow-y-auto">
          <div className="relative bg-white p-8 rounded-lg shadow-lg w-full sm:w-4/5 md:w-1/2 lg:w-1/3 xl:w-1/3 max-h-[80vh] overflow-y-auto">
            {/* Close Button for Form */}
            <button
              className="absolute top-3 right-4 text-red-500 hover:text-gray-900 text-2xl font-bold"
              onClick={handleCloseForm}
            >
              &times;
            </button>

            <h2 className="text-2xl font-bold text-blue-600 mb-4">Apply for {selectedJob.title}</h2>
            <form onSubmit={handleSubmit} className="space-y-8 max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
              <div>
                <label htmlFor="name" className="block text-lg font-semibold text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div>
                <label htmlFor="about" className="block text-lg font-semibold text-gray-700">
                  About Yourself
                </label>
                <textarea
                  id="about"
                  name="about"
                  value={formData.about}
                  onChange={handleChange}
                  rows={5}
                  className="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Tell us a bit about yourself and your teaching experience"
                  required
                />
              </div>
              <div>
                <label htmlFor="youtubeLink" className="block text-lg font-semibold text-gray-700">
                  YouTube Channel Link
                </label>
                <input
                  type="url"
                  id="youtubeLink"
                  name="youtubeLink"
                  value={formData.youtubeLink}
                  onChange={handleChange}
                  className="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter the link to your YouTube channel"
                  required
                />
              </div>
              <div>
                <label htmlFor="resume" className="block text-lg font-semibold text-gray-700">
                  Upload Resume (PDF)
                </label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  accept=".pdf"
                  onChange={handleFileChange}
                  className="mt-2 block w-full text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100"
                  required
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="px-8 py-3 bg-blue-600 text-white text-lg font-semibold rounded-full shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </div>
            </form>

            {showSuccessPopup && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
                  <div className="text-green-500 text-6xl mb-4">✔</div>
                  <p className="text-lg font-semibold">Thanks for applying!</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Teacher;