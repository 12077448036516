import React from 'react';
import flogo from '../assets/images/cut.png'
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className=" text-white py-8 p-4"
    style={{backgroundColor:'#050ADF',}}>
      <div className=' flex'>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* First Column */}
        <div className="space-y-4">
          <h4 className="text-lg font-semibold">SparkMinds</h4>
          <ul className="space-y-2">
            {/* <li>Teach on Spark Minds</li> */}
            <li>Get the app</li>
            <li>About us</li>
            <li>Contact us</li>
          </ul>
        </div>

        {/* Second Column */}
        <div className="space-y-4">
          <ul className="space-y-2">
            <b><li><Link to="/become-teacher" className="mr-6  text-lg">Careers</Link> </li></b>
            <li>Blog</li>

            <li>Affiliate</li>
            <li>Investors</li>
          </ul>
        </div>

        {/* Third Column */}
        <div className="space-y-4">
          <ul className="space-y-2">
            <li>Terms</li>
            <li>Privacy policy</li>
            <li>Sitemap</li>
            <li>Accessibility statement</li>
            <li>Help and Support</li>
          </ul>
        </div>
      </div>
              {/* Language Selector */}
              <div className=" h-8 border border-white px-4 py-2 rounded-full flex items-center space-x-2 cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2a10 10 0 100 20 10 10 0 000-20zM2.05 13h19.9M12 2v20" />
          </svg>
          <span>English</span>
        </div>

        </div>

      <div className="  flex items-center justify-around pt-4">
        {/* Logo and Copyright */}
        <div className=" ">
  {/* <img src={flogo} alt="Spark Minds" className=" h-10" /> */}
  <a href="https://myrsv.com" target="_blank" rel="noopener noreferrer" className=" hover:underline">
    © 2024 RSV Infotech Pte. Ltd.
  </a>
</div>
      </div>
    </footer>
  );
};

export default Footer;
